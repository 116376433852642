import classNames from 'classnames';
import {
  CustomizationUnit,
  applyCustomization,
} from 'common/repositories/customization';
import { MaybeText } from 'components/common/maybe';
import { AppLayoutSizeContext } from 'components/widgets/layout/app/context';
import React from 'react';
import structuralStyles from 'styles/layout.css';

export interface StatusViewScaffoldProps {
  height?: number;
  image?(size: number): React.ReactNode;
  title: React.ReactNode;
  description: React.ReactNode;
  fullHeight?: boolean;

  customization?: {
    /** Offset from the header in pixels. This is only used when fullHeight is set to true because Empty View needs to fill the entire screen and that requires knowledge of the header height*/
    root?: CustomizationUnit<React.ComponentProps<'div'>>;
    title?: CustomizationUnit<Partial<React.ComponentProps<typeof MaybeText>>>;
    description?: CustomizationUnit<
      Partial<React.ComponentProps<typeof MaybeText>>
    >;
  };
}

export default function StatusViewScaffold(props: StatusViewScaffoldProps) {
  const {
    height = 250,
    image,
    title,
    description,
    customization,
    fullHeight = true,
  } = props;
  const { header, footer } = React.useContext(AppLayoutSizeContext);

  return (
    <div
      style={
        fullHeight
          ? {
              height: `calc(100dvh - ${header.height}px - ${footer.height}px - 64px)`,
            }
          : undefined
      }
    >
      <div
        {...applyCustomization(
          {
            className: classNames(
              structuralStyles.flexbox({
                direction: 'column',
                justify: 'center',
              }),
              structuralStyles.fill({ height: true, width: true }),
            ),
          },
          [customization?.root],
        )}
      >
        <div
          style={{ height }}
          className={classNames(
            structuralStyles.flexbox({ justify: 'center' }),
            structuralStyles.padding({ bottom: 64 }),
          )}
        >
          {image?.(height)}
        </div>
        <div
          className={classNames(
            structuralStyles.flexbox({
              direction: 'column',
              gap: 8,
            }),
            structuralStyles.fill({ width: true }),
          )}
          style={{
            paddingLeft: 24,
            paddingRight: 24,
          }}
        >
          <MaybeText
            {...applyCustomization(
              {
                textVariant: 'h1',
                ta: 'center',
                mx: 24,
              },
              [customization?.title],
            )}
          >
            {title}
          </MaybeText>
          <MaybeText
            {...applyCustomization(
              {
                textVariant: 'body1Regular',
                ta: 'center',
                mx: 24,
              },
              [customization?.description],
            )}
          >
            {description}
          </MaybeText>
        </div>
      </div>
    </div>
  );
}
