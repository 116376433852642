import { AssetPaths } from 'common/constants/assets';
import Separator from 'components/common/separator';
import Text from 'components/elements/text/base';
import Image from 'next/image';
import React from 'react';

import StatusViewScaffold, { StatusViewScaffoldProps } from './scaffold';

interface Props {
  title?: React.ReactNode;
  description?: React.ReactNode;
  imageSource?: string;
  fullHeight?: boolean;
  below?: React.ReactNode;
  customization?: StatusViewScaffoldProps['customization'];
}

export default function ErrorView(props: Props) {
  const {
    title = 'Oops',
    description,
    imageSource,
    below,
    customization,
    fullHeight,
  } = props;
  return (
    <StatusViewScaffold
      title={
        <Text ta="center" textVariant="h1">
          {title}
        </Text>
      }
      fullHeight={fullHeight}
      description={
        <>
          <Text
            textVariant="body1Regular"
            ta="center"
            textColor="foregroundTertiary"
          >
            {description}
          </Text>
          {below && (
            <>
              <Separator gap={16} direction="vertical" />
              {below}
            </>
          )}
        </>
      }
      height={240}
      image={(size) => (
        <Image
          width={size}
          height={size}
          alt="Oops"
          src={imageSource ?? AssetPaths.ServerError}
          onError={(event: any) => {
            event.target.id = AssetPaths.Fallback;
            event.target.srcset = AssetPaths.Fallback;
          }}
        />
      )}
      customization={customization}
    />
  );
}
